<template>
  <span>
    <span
      @click="toggle = true"
      v-if="!docConfigId"
      class="text-success pointer">
      <fa icon="plus"></fa>
    </span>

    <span
      @click="toggle = true"
      v-if="docConfigId"
      class="text-warning pointer">
      <fa icon="pencil-alt"></fa>
    </span>

    <b-modal v-model="toggle" :title="title">
      <div class="form-row">
        <DocConfigFormInventory
          placeholder="เลือกรายการ..."
          class="col-12"
          select="id"
          :docType="docType"
          :templateType="templateType"
          v-model="formData.inventoryId"
          :types="['payroll']"
          :validations="[
            {text: 'required!', value: $v.formData.inventoryId.$dirty && $v.formData.inventoryId.$error}
          ]">
          <template slot-scope="option">
            {{option.inventory.code}} ({{option.inventory.name}})
          </template>
        </DocConfigFormInventory>

        <DocConfigFormAccount
          label="บัญชี"
          placeholder="เลือกรายการ..."
          class="col-12"
          select="id"
          :docType="docType"
          :templateType="templateType"
          v-model="formData.accountId"
          :validations="[
            {text: 'required!', value: $v.formData.accountId.$dirty && $v.formData.accountId.$error}
          ]">
          <template slot-scope="option">
            {{option.account.code}} ({{option.account.name}})
          </template>
        </DocConfigFormAccount>

        <sgv-input-number
          label="จำนวน"
          class="col-6"
          v-model="formData.qty"
          :precision="2"
          minus
          :validations="[
            {text: 'required!', value: $v.formData.qty.$dirty && $v.formData.qty.$error}
          ]">
        </sgv-input-number>

        <sgv-input-currency
          label="ราคา"
          class="col-6"
          v-model="formData.price"
          :precision="2"
          minus
          :validations="[
            {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
          ]">
        </sgv-input-currency>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <button
          type="button"
          class="btn btn-success"
          v-if="!docConfigId"
          @click="addData">
          เพิ่ม
        </button>

        <button
          type="button"
          class="btn btn-warning"
          v-if="docConfigId"
          @click="addData">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-primary"
          @click="cancel()">
          กลับ
        </button>
      </template>
    </b-modal>

  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormInventory from '@/views/doc_core/components/DocConfigFormInventory'
import DocConfigFormAccount from '@/views/doc_core/components/DocConfigFormAccount'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        inventoryId: null,
        accountId: null,
        qty: 0,
        price: 0,
        isTag: false
      }
    }
  },
  validations: {
    formData: {
      inventoryId: {required},
      accountId: {required},
      qty: {required},
      price: {required},
    }
  },
  methods: {
    addData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = {
        inventoryId: this.formData.inventoryId,
        accountId: this.formData.accountId,
        options: {
          qty: this.formData.qty,
          price: this.formData.price,
          isTag: false
        }
      }
      this.$emit('added', {docConfigId: this.docConfigId, input})
      this.toggle = false
    }
  },
  watch: {
    toggle (v) {
      if (v === false) {
        this.$v.formData.$reset()

        this.formData.inventoryId = null
        this.formData.accountId = null
        this.formData.qty = 0
        this.formData.price = 0
        this.formData.isTag = false
      } else if (v === true && this.docConfigId && this.item) {
        this.formData.inventoryId = this.item.inventoryId
        this.formData.accountId = this.item.accountId
        this.formData.qty = this.item.options.qty
        this.formData.price = this.item.options.price
        this.formData.isTag = this.item.options.isTag
      }
    }
  },
  components: {
    DocConfigFormInventory,
    DocConfigFormAccount
  }
}
</script>
