<template>
  <div class="">
    ประกันสังคม:
    <ul v-if="socialSecurities.length > 0">
      <li
        v-for="match in matches"
        :key="match.id">
        <span
          v-if="method === 'edit' && !selected.includes(match.id)"
          class="text-success pointer"
          @click="addDiscount(match.id)">
          <fa icon="check"></fa>
        </span>
        <span
          v-if="method === 'edit' && selected.includes(match.id)"
          class="text-danger pointer"
          @click="deleteDiscount(match.id)">
          <fa icon="trash"></fa>
        </span>

        {{match.name}}

        <small>
          <div v-if="match.inventory" class="text-warning">
            {{match.inventory.code}} ({{match.inventory.name}})
          </div>
          <div v-if="match.account" class="text-info">
            บัญชี: {{match.account.code}} ({{match.account.name}})
          </div>

          <div class="">
            <span class="text-success">
              {{match.options.minValue}}
            </span>

            <span class="mx-1"><fa icon="less-than-equal"></fa></span>

            {{match.options.value}}

            <span class="mx-1"><fa icon="less-than-equal"></fa></span>

            <span class="text-danger">
              {{match.options.maxValue}}
            </span>
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_DOC_CONFIG_SOCIAL_SECURITY,
  LIST_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY,
  CREATE_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY,
  DESTROY_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY,
} from './graph'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      socialSecurities: [],
      items: []
    }
  },
  apollo: {
    socialSecurities: {
      query () {
        return LIST_DOC_CONFIG_SOCIAL_SECURITY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            limit: 100
          }
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.items
    },
    items: {
      query () {
        return LIST_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId
        }
      },
      fetchPolicy: 'no-cache',
    }
  },
  computed: {
    selected () {
      return this.items.map(v => v.childId)
    },
    matches () {
      if (['edit', 'add'].includes(this.method)) {
        return this.socialSecurities
      } else {
        return this.socialSecurities.filter(v => this.selected.includes(v.id))
      }
    }
  },
  methods: {
    addDiscount (socialSecurityId) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          socialSecurityId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteDiscount (socialSecurityId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_JOB_DESCRIPTION_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          socialSecurityId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css">
</style>
