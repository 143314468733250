<template>
  <span>
    <span
      @click="toggle = true"
      v-if="!parentId"
      class="text-success pointer">
      <fa icon="plus"></fa>
    </span>

    <span
      @click="toggle = true"
      v-if="parentId"
      class="text-warning pointer">
      <fa icon="pencil-alt"></fa>
    </span>

    <b-modal v-model="toggle" :title="title">
      <div class="form-row">
        <sgv-input-text
          label="ค่า"
          class="col-6"
          v-model="formData.value"
          :validations="[
            {text: 'required!', value: $v.formData.value.$dirty && $v.formData.value.$error}
          ]">
        </sgv-input-text>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <button
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-primary"
          @click="cancel()">
          กลับ
        </button>
      </template>
    </b-modal>

  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    parentId: {
      type: Number,
      required: true
    },
    discountId: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        value: 0,
      }
    }
  },
  validations: {
    formData: {
      value: {required},
    }
  },
  methods: {
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = {
        options: {
          value: this.formData.value
        }
      }
      this.$emit('update', {
        parentId: this.parentId,
        discountId: this.discountId,
        input
      })
      this.toggle = false
    }
  },
  watch: {
    toggle (v) {
      if (v === false) {
        this.$v.formData.$reset()

        this.formData.value = 0
      } else if (v === true && this.parentId && this.item) {
        this.formData.value = this.item.options.value
      }
    }
  }
}
</script>
