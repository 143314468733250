<template>
  <div class="">
    ฐานภาษี:
    <ul v-if="taxs.length > 0">
      <li
        v-for="match in matches"
        :key="match.id">
        <span
          v-if="method === 'edit' && !selected.includes(match.id)"
          class="text-success pointer"
          @click="addTax(match.id)">
          <fa icon="check"></fa>
        </span>
        <span
          v-if="method === 'edit' && selected.includes(match.id)"
          class="text-danger pointer"
          @click="deleteTax(match.id)">
          <fa icon="trash"></fa>
        </span>

        {{match.name}}

        <small>
          <div v-if="match.inventory" class="text-warning">
            {{match.inventory.code}} ({{match.inventory.name}})
          </div>
          <div v-if="match.account" class="text-info">
            บัญชี: {{match.account.code}} ({{match.account.name}})
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_DOC_CONFIG_TAX,
  LIST_DOC_CONFIG_JOB_DESCRIPTION_TAX,
  CREATE_DOC_CONFIG_JOB_DESCRIPTION_TAX,
  DESTROY_DOC_CONFIG_JOB_DESCRIPTION_TAX,
} from './graph'

export default {
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      taxs: [],
      items: []
    }
  },
  apollo: {
    taxs: {
      query () {
        return LIST_DOC_CONFIG_TAX(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            limit: 100
          }
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.items
    },
    items: {
      query () {
        return LIST_DOC_CONFIG_JOB_DESCRIPTION_TAX(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId
        }
      },
      fetchPolicy: 'no-cache',
    }
  },
  computed: {
    selected () {
      return this.items.map(v => v.childId)
    },
    matches () {
      if (['edit', 'add'].includes(this.method)) {
        return this.taxs
      } else {
        return this.taxs.filter(v => this.selected.includes(v.id))
      }
    }
  },
  methods: {
    addTax (taxId) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_JOB_DESCRIPTION_TAX(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          taxId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteTax (taxId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_JOB_DESCRIPTION_TAX(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          taxId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css">
</style>
