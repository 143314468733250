<template>
  <div class="">
    {{title}}:
    <DocConfigDetailJobDescriptionProfileModal
      :title="title"
      :profileType="profileType"
      :docType="docType"
      :templateType="templateType"
      :method="method"
      @added="addProfile">
    </DocConfigDetailJobDescriptionProfileModal>
    <ul v-if="items.length > 0">
      <li
        v-for="item in items"
        :key="item.id">

        <small>
          <div class="text-warning">
            <span
              v-if="method === 'edit'"
              class="text-danger pointer mr-1"
              @dblclick="deleteProfile(item.id)">
              <fa icon="trash"></fa>
            </span>

            {{item.inventory.code}} ({{item.inventory.name}})
            <span v-if="item.options.isTag">
              <fa icon="random" class="text-success"></fa>
            </span>

            <DocConfigDetailJobDescriptionProfileModal
              class="ml-1"
              :title="title"
              :profileType="profileType"
              :docType="docType"
              :templateType="templateType"
              :method="method"
              :docConfigId="item.id"
              :item="item"
              @added="addProfile">
            </DocConfigDetailJobDescriptionProfileModal>
          </div>

          <div class="text-info">
            บัญชี: {{item.account.code}} ({{item.account.name}})
          </div>
          <div class="">
            จำนวน:
            <span :class="{
              'text-success': item.options.qty > 0,
              'text-danger': item.options.qty < 0,
            }">
              {{item.options.qty}} {{item.inventory.unit.name}}
            </span>

            ราคา:
            <span :class="{
              'text-success': item.options.qty > 0,
              'text-danger': item.options.qty < 0,
            }">
              {{item.options.price}} บาท
            </span>
          </div>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import DocConfigDetailJobDescriptionProfileModal from './DocConfigDetailJobDescriptionProfileModal'
import {
  LIST_DOC_CONFIG_JOB_DESCRIPTION_PROFILE,
  CREATE_DOC_CONFIG_JOB_DESCRIPTION_PROFILE,
  DESTROY_DOC_CONFIG_JOB_DESCRIPTION_PROFILE,
} from './graph'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    profileType: {
      type: String,
      required: true
    },
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_DOC_CONFIG_JOB_DESCRIPTION_PROFILE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          parentId: this.docConfigId,
          types: [this.profileType],
        }
      },
      fetchPolicy: 'no-cache',
    }
  },
  methods: {
    addProfile ({docConfigId, input}) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_JOB_DESCRIPTION_PROFILE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          docConfigId,
          input: {
            ...input,
            type: this.profileType
          }
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteProfile (profileId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_JOB_DESCRIPTION_PROFILE(this.templateType),
        variables: {
          docType: this.docType,
          parentId: this.docConfigId,
          profileId
        }
      })
      .then(() => {
        this.$apollo.queries.items.refetch()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  components: {
    DocConfigDetailJobDescriptionProfileModal
  }
}
</script>

<style lang="css">
</style>
