var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(!_vm.docConfigId)?_c('span',{staticClass:"text-success pointer",on:{"click":function($event){_vm.toggle = true}}},[_c('fa',{attrs:{"icon":"plus"}})],1):_vm._e(),(_vm.docConfigId)?_c('span',{staticClass:"text-warning pointer",on:{"click":function($event){_vm.toggle = true}}},[_c('fa',{attrs:{"icon":"pencil-alt"}})],1):_vm._e(),_c('b-modal',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [(!_vm.docConfigId)?_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"},on:{"click":_vm.addData}},[_vm._v(" เพิ่ม ")]):_vm._e(),(_vm.docConfigId)?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.addData}},[_vm._v(" แก้ไข ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return cancel()}}},[_vm._v(" กลับ ")])]}}]),model:{value:(_vm.toggle),callback:function ($$v) {_vm.toggle=$$v},expression:"toggle"}},[_c('div',{staticClass:"form-row"},[_c('DocConfigFormInventory',{staticClass:"col-12",attrs:{"placeholder":"เลือกรายการ...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"types":['payroll'],"validations":[
          {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && _vm.$v.formData.inventoryId.$error}
        ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.inventory.code)+" ("+_vm._s(option.inventory.name)+") ")]}}]),model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}}),_c('DocConfigFormAccount',{staticClass:"col-12",attrs:{"label":"บัญชี","placeholder":"เลือกรายการ...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
          {text: 'required!', value: _vm.$v.formData.accountId.$dirty && _vm.$v.formData.accountId.$error}
        ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.account.code)+" ("+_vm._s(option.account.name)+") ")]}}]),model:{value:(_vm.formData.accountId),callback:function ($$v) {_vm.$set(_vm.formData, "accountId", $$v)},expression:"formData.accountId"}}),_c('sgv-input-number',{staticClass:"col-6",attrs:{"label":"จำนวน","precision":2,"minus":"","validations":[
          {text: 'required!', value: _vm.$v.formData.qty.$dirty && _vm.$v.formData.qty.$error}
        ]},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}}),_c('sgv-input-currency',{staticClass:"col-6",attrs:{"label":"ราคา","precision":2,"minus":"","validations":[
          {text: 'required!', value: _vm.$v.formData.price.$dirty && _vm.$v.formData.price.$error}
        ]},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }